import { Pincodes } from "context/interfaces";
import { SupporterData } from "components/UI-Rewamp/FundraiserDetails/fundraiserDetails";
import {
  MAXIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_IN,
  MAXIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_US,
  MINIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_IN,
  MINIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_US,
  TOKEN_FREQUENCY_BEFORE_FOR_DATES
} from "../config"
import DBStorage from "./DBStorage"
import Analytics from "./analytics";
import axios from 'axios';
import { pageRoutes } from "../routeConstant";
import { causeNameSlugList } from "../constant";
import { logAmplitudeEvent } from "./amplitude";

declare global {
  interface Window {
    getUTMParameters: any;
  }
}

interface OrderInfo {
  isDefaultAmount: boolean;
  redirection_checkout_status: boolean;
}
interface Tags {
  id: string;
  name: string;
}
export const getUTMParameters = (searchStr: string): Record<string, string> => {
  const urlParams: any = new URLSearchParams(searchStr);
  const utmParameters: Record<string, string> = {};
  // Iterate over each parameter and check if it starts with 'utm_'
  for (const [key, value] of urlParams.entries()) {
    if (key.startsWith('utm_')) {
      utmParameters[key] = value;
    }
  }
  return utmParameters;
}
export const isObject=(value:any)=> {
  return value !== null && typeof value === 'object';
}
export const getUTMParamWindowMethod = (queryUrl?: string) => {
  try {
    const getUTMs =getUTMParameters(queryUrl||"")
    if (isObject(getUTMs) && queryUrl) {
      let concateUTM = {...getUTMs,...getUTMParameters(queryUrl)}
      return concateUTM
    }
    if (queryUrl) {
      return getUTMParameters(queryUrl);
    }
    else return getUTMs;
  }
  catch {
    if (queryUrl)
      return getUTMParameters(queryUrl);
  }
}

export const getRemainingDays = (dateString: any) => {
  const date = new Date(dateString);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);   // Set the current date to the start of the day
  date.setHours(0, 0, 0, 0);   // Set the target date to the start of the day
  const difference = date.getTime() - currentDate.getTime();// Calculate the difference in milliseconds
  const remainingDays = Math.max(Math.floor(difference / (1000 * 60 * 60 * 24)), 0);// Convert milliseconds to days
  return remainingDays;
};


export const formatProjectDescription = (data: any) => {
  let description = ''
  if (data.issue) {
    description += '<div class="mb-3">'
    description += '<h4 class="project-heading">Issue</h4>'
    description += data.issue
    description += '</div>'
  }
  if (data.action) {
    description += '<div class="mb-3">'
    description += '<h4 class="project-heading">Action</h4>'
    description += data.action
    description += '</div>'
  }
  if (data.impact) {
    description += '<div class="mb-3">'
    description += '<h4 class="project-heading">Impact</h4>'
    description += data.impact
    description += '</div>'
  }
  return description
}

export const amountConverterHelper = (currencycode: any, amount: any, exchangeRate: any, currencyList: any) => {
  if (currencycode) {
    if (currencycode === DBStorage.get('currencyCode', false)) {
      return Math.ceil(amount);
    } else {
      let findCurrencyObj = currencyList.filter((item: any) => item.currency_code == currencycode)
      if (findCurrencyObj.length) {
        return Math.ceil(amount * exchangeRate / findCurrencyObj[0].exchange_rate)
      }
      else {
        return 0
      }
    }
  }
  else {
    return 0
  }
}

export const debounce = (fn: any, time: any) => {
  let timeoutId: any
  return wrapper
  function wrapper(...args: any) {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      timeoutId = null
      fn(...args)
    }, time)
  }
}

export const getQueryParams = (path: string) => {
  const params = path.split('&')
  let query: any = {}

  params.forEach(item => {
    item = item.replace('?', '')
    if (item.indexOf('=') >= 0) {
      const keyValue = item.split('=')
      query[keyValue[0]] = keyValue[1]
    }
  })

  return query
}

export const serializeQuery = (params: any, prefix?: any) => {
  const query: any = Object.keys(params).map((key) => {
    const value: any = params[key];

    if (params.constructor === Array)
      key = `${prefix}[]`;
    else if (params.constructor === Object)
      key = (prefix ? `${prefix}[${key}]` : key);

    if (typeof value === 'object')
      return serializeQuery(value, key);
    else
      return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
}
//NOTE: Not using anywhere .. just added for future refrence !! 
export const customGetUTMParameters = (queryUrl: string) => {
  const urlData: any = getQueryParams(queryUrl);
  const utmParameters: any = {};

  Object.keys(urlData).filter((item: string) => {
    if (item.includes('utm')) {
      Object.assign(utmParameters, { [item]: urlData[item] });
    }
  });

  if (Object.keys(utmParameters).length > 0) {
    return utmParameters;
  } else {
    return null;
  }
};

export const getUTMStoredInCookie = () => {
  const utmParamsInCookie = document.cookie.split(';');
  for (let i = 0; i < utmParamsInCookie.length; i++) {
    let cookieKey = utmParamsInCookie[i].split('=')[0];
    let cookieVal = utmParamsInCookie[i].split('=')[1];
    if (cookieKey.includes('utm_source')) {
      return {
        utm_source: cookieVal,
      }
    }
  }
  return null;
}

export const convertJSONtoCSV = (json: any, fileTitle: String, additionalFields: any) => {
  if (!Array.isArray(json)) {
    return;
  }
  const headers = [
    'Date',
    'Reference Number',
    'Currency',
    'Amount',
    'Anonymous',
    'Donor Name',
    'Donor Email',
    'Donor Phone'
  ];

  if (additionalFields) {
    additionalFields.forEach((field: any) => headers.push(field.label));
  }

  const returnData = [
    [...headers]
  ];

  json.forEach(row => {
    const rowData = [
      new Date(row.created_at).toLocaleString(),
      row.order_id,
      row.currency_code,
      row.amount,
      row.is_anonymous === true ? 'Yes' : 'No',
      row.user.name,
      row.user.email || '',
      row.user.phone ? "'" + row.user.phone : ''
    ];

    if (additionalFields) {
      additionalFields.forEach((field: any) => {
        rowData.push((row.additional_fields && row.additional_fields[field['id']]) || '')
      });
    }
    returnData.push(rowData);
  });

  const csvData = returnData.reduce((a, c) => a + `"${c.join('","')}"\n`, '');

  const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  if (navigator && (navigator as any).msSaveBlob) { // IE 10+
    (navigator as any).msSaveBlob(blob, exportedFilenmae);
  }
  else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

let initialdata: any[] = []
let scriptLoaded = { nextId: 0, data: initialdata };

const objSearch = function (object: any, item: any, obj: any) {
  if (obj) {
    for (let i = 0; i < object.length; i++) {
      if (object[i][obj] === item) {
        return i;
      }
    }
  } else {
    for (let j = 0; j < object.length; j++) {
      if (object[j] === item) {
        return j;
      }
    }
  }
  return -1;
}

export const loadScript = function (src: any, force: any = undefined) {
  // const _self = this;
  return new Promise(function (resolve: any, reject: any) {
    let s;
    if (objSearch(scriptLoaded.data, src, 'src') >= 0) {
      if (force === true) {
        const id = objSearch(scriptLoaded.data, null, null);
        const oldElement: any = document.getElementById('dynscript-' + id);
        s = document.createElement('script');
        s.src = src;
        s.id = 'dynscript-' + id;
        s.onload = resolve;
        s.onerror = reject;
        document.head.replaceChild(s, oldElement);
      } else {
        resolve();
      }
    } else {
      const id = scriptLoaded.nextId++;
      let xyz: any = { id, src }
      scriptLoaded.data.push(xyz)
      s = document.createElement('script');
      s.src = src;
      s.id = 'dynscript-' + id;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    }
  });
}

export const convertJSONtoCSVContacts = (json: any, fileTitle: String) => {
  if (!Array.isArray(json)) {
    return;
  }
  const headers = [
    'Name',
    'Email',
    'Source',
    'Date'
  ];

  const returnData = [
    [...headers]
  ];

  json.forEach(row => {
    const rowData = [
      row.name,
      row.email,
      row.source,
      new Date(row.createdDate).toLocaleString(),
    ];

    returnData.push(rowData);
  });

  const csvData = returnData.reduce((a, c) => a + `"${c.join('","')}"\n`, '');

  const exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  if (navigator && (navigator as any).msSaveBlob) { // IE 10+   (Type assertion here)
    (navigator as any).msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const csvToArray = (strData: any) => {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  let strDelimiter = ',';
  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    (
      // Delimiters.
      '(\\' + strDelimiter + '|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' + strDelimiter + '\\r\\n]*))'
    ),
    'gi'
  );
  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData: any[] = [[]];
  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;
  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(strData)) {
    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];
    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      (strMatchedDelimiter != strDelimiter)
    ) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }
    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      var strMatchedValue: any = arrMatches[2].replace(
        new RegExp('""', 'g'),
        '"'
      );
    } else {
      // We found a non-quoted value.
      var strMatchedValue: any = arrMatches[3];
    }
    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }
  // Return the parsed data.
  return (arrData);
}

export const nFormatter = (num: any) => {
  if (`${num}`.length < 6) {
    return num.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'decimal',
      currency: 'INR'
    })
  } else {
    return convertNumbertoLakhCrore(num);
  }
}

export const convertNumbertoLakhCrore = (num: any) => {
  var val: any = `${Math.abs(num)}`;
  if (val >= 10000000) {
    val = '₹' + (val / 10000000).toFixed(0) + ' Cr';
  } else if (val >= 100000) {
    val = '₹' + (val / 100000).toFixed(0) + ' Lakh';
  }
  return val;
}

const getOrdinalNum = (n: any) => {
  return (
    n +
    (n > 0
      ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
      : '')
  );
};

export const formatDate = (date: any) => {
  const day = getOrdinalNum(date.getDate());
  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const monthlyDateFormate = (date:any, month?:string) => {
  return date.toLocaleString("en-US", { month: month || "short", year: "numeric" });
}

const getMonthName = (n: any) => {
  const monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return monthList[n];
};

export const getShortCurrencyString = (value: number, currencyCode: string, currencyList: any, exchangeRate: number) => {
  let locale: string = 'en-IN';
  let convertedValue: any;
  if (currencyCode == "USD") {
    convertedValue = amountConverterHelper("INR", value, exchangeRate, currencyList)
    locale = "en-US"
  } else {
    convertedValue = value
  }
  const options: { notation: "compact", maximumFractionDigits: number } = {
    notation: "compact",
    maximumFractionDigits: 1
  };
  const formatter = Intl.NumberFormat(locale, options)
  return formatter.format(convertedValue)
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};
export const DONATION_TYPE_LIST = Object.freeze({
  ONE_TIME: "ONE-TIME",
  MONTHLY: "MONTHLY",
  QUARTERLY : 'QUARTERLY',
  ANNUALLY : 'ANNUALLY'
})

export const renderGiveNowButtonText = (data: any) => {

  return data && data?.type === DONATION_TYPE_LIST.MONTHLY ? 'Donate Monthly' : 'Give Now'
}

//This function does the same logic as amountconverthelper just ignoring the localstorage currency
export const convertAmount = (currencycode: string, exchangeRate: any, amount: number, currencyList: any) => {
  if (currencycode) {
    let findCurrencyObj = currencyList?.filter((item: any) => item.currency_code == currencycode)
    if (findCurrencyObj?.length) {
      return Math.ceil(amount * exchangeRate / findCurrencyObj[0].exchange_rate)
    }
    else {
      return 0
    }
  }
  else {
    return 0
  }
}
// To get the boolean when the expected content is visible into the view on scroll
export const isScrolledIntoView = (el: any) => {
  if (el) {
    var rect = el?.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    return isVisible;
  }
  else return false
}

export const monthlyDonationPrority = (amount: number, currency_code: string) => {
  let prority = null;
  if (currency_code === 'INR') {
    prority = amount < MINIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_IN ? 'low' : (amount > MAXIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_IN ? 'high' : null)
  } else {
    prority = amount < MINIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_US ? 'low' : (amount > MAXIMUM_AMOUNT_TO_TRIGGER_MONTHLY_PAYMENT_US ? 'high' : null)
  }
  return prority;
}

export const trackData = (type: any) => {
  let ctaTrackingInfo;
  if (type === "sticky_giveNow") {
    ctaTrackingInfo = {
      device: "mobile",
      cta_type: "button",
      location: "sticky"
    }
  }
  if (type === "initialGiveNow") {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: "first_fold"
    }
  }
  if (type === "donors_list_popup") {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: "donors_list_popup"
    }
  }
  if (type === "midFold_giveNow") {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: "mid_fold"
    }
  }
  if (type === "video_popup") {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: "video_popup"
    }
  }
  if ((type === "UPI") || (type === "Card") || (type === "Transfer")) {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "banner",
      location: type === "UPI" ? "upi" : (type === "Card" ? "cards" : "bank_transfer")
    }
  }
  if (type === 'fundraiser_card') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: 'donate_cta_fr_Card'
    }
  }

  if (type === 'ngoprofile_main_donate_btn') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: 'ngoprofile_maindonatebutton'
    }
  }

  if (type === 'ngoprofile_donate_btn_card_1') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: 'ngoprofile_donatebutton1'
    }
  }

  if (type === 'ngoprofile_donate_btn_card_2') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: 'ngoprofile_donatebutton2'
    }
  }

  if (type === 'ngoprofile_create_fundraiser_btn') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "button",
      location: 'create_fundraiser_ngoprofile'
    }
  }

  if (type === 'ngoprofile_important_link') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "link",
      location: 'imporant_link_ngoprofile'
    }
  }

  if (type === 'ngoprofile_create_supporting_fundraiser') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "link",
      location: 'campaign_card_ngoprofile'
    }
  }

  if (type === 'ngoprofile_create_fundraiser') {
    ctaTrackingInfo = {
      device: getDeviceType() === "mobile" ? "mobile" : "desktop",
      cta_type: "link",
      location: 'cta_section_ngoprofile'
    }
  }

  return ctaTrackingInfo
}

export const getUserLocation = () => {
  return new Promise(async (resolve: any, reject: any) => {
    let cooridnateArr: any[] = [];
    let location = { type: 'point', coordinates: cooridnateArr }
    try {
      const geoLocation: any = await axios.get('https://geolocation-db.com/json/')
      if (geoLocation?.data?.latitude && geoLocation?.data?.longitude) {
        location.coordinates = [
          geoLocation.data.longitude,
          geoLocation.data.latitude
        ]
        resolve(location);
      }
      else {
        // Do Nothing
        reject('Location data not available');
      }
    }
    catch (e) {
      // Do Nothing 
      reject('Location data not available');
    }
  });
}

export const hasTaxExemption = (exemptions: any, currency_code: string, canProvide10be: boolean) => {
  if (currency_code === 'INR') {
    if (canProvide10be) {
      return true;
    } else {
      return false;
    }
  } else if (currency_code === 'USD') {
    if (exemptions) {
      const exemptionKey = '501(c) (3)';
      const thisExemption = exemptions.filter((item: any) => item.label === exemptionKey);
      if (thisExemption.length === 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }
  return false;
}

export const hasTaxExemptionForSearch = (exemptions: any, currency_code: string, canProvide10be: boolean) => {
  if (currency_code === 'INR') {
    if (canProvide10be) {
      return true;
    } else {
      return false;
    }
  } else if (currency_code === 'USD') {
    if (exemptions) {
      const exemptionKey = '501(c) (3)';
      const thisExemption = exemptions.filter((item: any) => item.label === exemptionKey);
      if (thisExemption.length === 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }
  return false;
}

//trigger addToCartEvent
export const triggerAddToCarytAnalytics = (analyticObg: any) => {
  const { amount, orderInfo, currencySymbolData, campaignData, userData, donation_currency } = analyticObg || {}
  const { isDefaultAmount, redirection_checkout_status } = orderInfo || {}
  try {
    Analytics.addToCart({
      id: campaignData?._id,
      name: campaignData?.slug,
      title: campaignData?.title,
      amount,
      brand: campaignData?.nonprofit?.slug
        ? campaignData?.nonprofit?.slug
        : campaignData?.nonprofit?.name,
      variant: "Campaign",
      category:
        campaignData?.project && campaignData?.project?.causes
          ? campaignData?.project?.causes[0]
          : "",
      currency_symbol: currencySymbolData,
      isDefaultAmount: isDefaultAmount,
      redirection_checkout_status: redirection_checkout_status,
      revenue_type: campaignData?.type,
      donation_currency
    }, userData);
  }
  catch (e) {
    //do nothing
  }
};

export const getUserCityAndState = (pinCodesMap: Array<Pincodes>, userPin: string) => {
  return pinCodesMap.filter((pinCodeObj: Pincodes) => {
    // Pincodes could be stored in the system with digits with length of 3,4,5 or 6 numbers as they are grouped for few cities. 
    // Checking for match on all these cases 
    // Step 1: Look for exact match 
    let exactMatch = pinCodeObj.pincodes.includes(userPin);
    if (exactMatch) return exactMatch;
    // Step 2: Look for 5 digit grouping
    let slicedToFiveDigitMatch = pinCodeObj.pincodes.includes(userPin.slice(0, 5));
    if (slicedToFiveDigitMatch) return slicedToFiveDigitMatch;
    // Step 3: Look for 4 digit grouping
    let slicedToFourDigitMatch = pinCodeObj.pincodes.includes(userPin.slice(0, 4));
    if (slicedToFourDigitMatch) return slicedToFourDigitMatch;
    // Step 4: Look for 3 digit grouping
    let slicedToThreeDigitMatch = pinCodeObj.pincodes.includes(userPin.slice(0, 3));
    if (slicedToThreeDigitMatch) return slicedToThreeDigitMatch;

  });


};
//Grouping array for custom carousel slider component
export const groupArray = (array: any, groupSizes: any) => {
  const groupedArray = [];
  let currentIndex = 0;
  for (let i = 0; i < groupSizes.length; i++) {
    const groupSize = groupSizes[i];
    const group = array.slice(currentIndex, currentIndex + groupSize);
    groupedArray.push(group);
    currentIndex += groupSize;
  }
  return groupedArray;
};
// Function to convert date string to a sortable format
export const convertToDate = (dateString: string) => {
  const parts = dateString.split(' ');
  const month = new Date(`${parts[1]} 1, 2000`).getMonth() + 1; // Get month number
  const day = parseInt(parts[0]); // Get day
  const year = parseInt(parts[2]); // Get year
  return new Date(`${year}-${month}-${day}`);
}

//Email validator
export const validateEmail = (email: string) => {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

//PAN Validator
export const validatePAN = (panNumber: any) => {
  const regPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return regPan.test(panNumber) ? true : false;
};

export const validateGSTNumber = (GSTNumber: string) => {
  const regGSTNumber = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return regGSTNumber.test(GSTNumber) ? true : false;
}
//common function to filter tags array only name 
export const getTags = (tags: any): string[] => {     //(tags: Array<Tags>): string[] => {
  if (tags && tags.length !== 0) {
    return tags.map((item: any) => {
      if (item.hasOwnProperty('name')) return item?.name
      else if (item.hasOwnProperty('title')) return item?.title
      else return item
    });
  } else {
    return [];
  }
};
export const giveFoundationNpName = 'Give Foundation'
export const giveNpName = 'Give'

export const checkIsGiveFoundation = (name: string) => name === giveFoundationNpName || name === giveNpName


export const getCountryNameAndFlag = (countryCode: string, countries: any) => {
  try {
    const countryObj = countries?.filter((country: any) => {
      return country?.country_code === countryCode;
    });

    return {
      countryName: countryObj[0]?.country_name,
      flag: countryObj[0]?.flag,
    };
  } catch (err) {

  }
}

export const determineIfAllowedForTicker = (donor: SupporterData) => {
  const currentDateAndTime = new Date();
  const donationDateAndTime = new Date(donor.created_at);

  // const howManyYearsFromDonation = Math.abs(currentDateAndTime.getFullYear() - donationDateAndTime.getFullYear());
  // const howManyMonthsFromDonation = Math.abs(currentDateAndTime.getMonth() - donationDateAndTime.getMonth());
  // const howManyDaysFromDonation = Math.abs(parseInt(donor.created_at.slice(8, 10)) - parseInt(currentDateAndTime.toISOString().slice(8,10)));
  var Difference_In_Time = currentDateAndTime.getTime() - donationDateAndTime.getTime();

  // To calculate the no. of days between two dates 
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  if (Difference_In_Days <= 15 && !donor.is_anonymous) {
    return true;
  }
  return false;
}

export const timePassedSinceDonation = (donor: SupporterData) => {
  const currentDateAndTime = new Date();
  const donationDateAndTime = new Date(donor.created_at);

  const howManyDaysFromDonation = Math.abs(parseInt(donor.created_at.slice(8, 10)) - parseInt(currentDateAndTime.toISOString().slice(8, 10)));
  const howManyHoursFromDonation = Math.floor(Math.abs((currentDateAndTime.valueOf() - donationDateAndTime.valueOf()) / 36e5));
  const howManyMinutesFromDonation = Math.round(Math.abs((((currentDateAndTime.valueOf() - donationDateAndTime.valueOf()) % 86400000) % 3600000) / 60000));
  const howManySecondsFromDonation = Math.round(Math.abs((currentDateAndTime.valueOf() - donationDateAndTime.valueOf()) / 1000));

  // console.log(`Days: ${howManyDaysFromDonation}, Hours: ${howManyHoursFromDonation}, Minutes: ${howManyMinutesFromDonation}, Seconds: ${howManySecondsFromDonation}, Date: ${donationDateAndTime}`)
  // this function checks time passed since donation only for allowed supporters based on determineIfAllowedForTicker returning true

  if (howManyDaysFromDonation >= 1 && howManyDaysFromDonation <= 5) {
    if (howManyDaysFromDonation === 1) {
      return `${howManyDaysFromDonation} day ago`;
    }
    return `${howManyDaysFromDonation} days ago`;
  } else if (howManyHoursFromDonation >= 1 && howManyHoursFromDonation <= 24) {
    if (howManyHoursFromDonation === 1) {
      return `${howManyHoursFromDonation} hour ago`;
    }
    return `${howManyHoursFromDonation} hours ago`;
  } else if (howManyMinutesFromDonation < 60 && howManyMinutesFromDonation >= 1) {
    if (howManyMinutesFromDonation === 1) {
      return `${howManyMinutesFromDonation} minute ago`;
    }
    return `${howManyMinutesFromDonation} minutes ago`;
  } else if (howManySecondsFromDonation < 60 && howManySecondsFromDonation >= 1) {
    if (howManySecondsFromDonation === 1) {
      return `${howManySecondsFromDonation} second ago`;
    }
    return `${howManySecondsFromDonation} seconds ago`;
  }
}

export const shouldShowAmount = (amount: number, currency: 'INR' | 'USD') => {
  if (currency === 'USD' && amount >= 5) {
    return true;
  } else if (currency === 'INR' && amount >= 500) {
    return true;
  }

  return false;
}
export const dropdownMonths = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const cancellationFlowAmpEventData = (userData:any, activity:any, manage_sub_action:any, manage_sub_reason:any, manage_sub_option:any) => {
  let eventData = {
      user_id : userData?._id, 
      fundraiser_id : activity?.campaign?._id,
      fundraiser_title : activity?.campaign?.title,
      amount_in_inr : activity?.amount_in_inr,
      currency : activity?.currency_code, 
      manage_sub_action :  manage_sub_action, // pause, amount_update, cancel
      manage_sub_reason : manage_sub_reason , 
      manage_sub_option  : manage_sub_option, // only for downgrade
      manage_sub_source : 'profile_page',
  }
  return (
    eventData
  )
}

export const getImageDimensions = (imageUrl: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = () => {
      reject(new Error("Error loading image."));
    };
    img.src = imageUrl;
  });
};

export const startFundraiserActionRoute = (user: any, history: any) => {
  const { is_admin } = user || false
  if (is_admin) {
    history.push(pageRoutes.FUNDRAISERS_CREATE_SELECT);
  } else {
    history.push(pageRoutes.FUNDRAISERS_CREATE_PROGRAM_LISTING)
  }
}

export const handleRedirect = async (link: string, ampEvent?: string, userData?: any) => {
  let eventProperties = { URL: link, user_id: userData?._id ? userData?._id : undefined }
  try {
    ampEvent && logAmplitudeEvent(ampEvent, eventProperties);
  } catch (error) {
    console.log(error);
  }
  if (window) {
    window.location.href = link
  }
}

export const isEqualToPathname = (title: string) => {
  const disallowedTitleNames = [
    ...causeNameSlugList,
    'elderly care',
    'animal welfare',
    'help the poor',
    'differently abled',
    'disaster relief',
    'save environment',
    'art and culture',
    'human rights'
  ];
  return disallowedTitleNames.includes(title?.trim());
}

export function parseJSONSafe(jsonString: string) {
  try {
    const parsedData = JSON.parse(jsonString);
    if (typeof parsedData === 'object' && parsedData !== null) {
      return parsedData;
    } else {
      throw new Error('Invalid JSON format');
    }
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null;
  }
}

export const getSearchParams = () => {
  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
    const utmParams = new URLSearchParams();

    // Only append if the parameter is not null or empty
    const utmSource = searchParams.get("utm_source");
    if (utmSource) utmParams.append("utm_source", utmSource);

    const utmMedium = searchParams.get("utm_medium");
    if (utmMedium) utmParams.append("utm_medium", utmMedium);

    const utmCampaign = searchParams.get("utm_campaign");
    if (utmCampaign) utmParams.append("utm_campaign", utmCampaign);

    const utmContent = searchParams.get("utm_content");
    if (utmContent) utmParams.append("utm_content", utmContent);

    const utmTerm = searchParams.get("utm_term");
    if (utmTerm) utmParams.append("utm_term", utmTerm);

    return utmParams.toString(); // Returns the query string with only available parameters
  } else {
    return "";
  }
};

export const scrollToSection = (sectionId: string) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth'
    });
    const scrollOffset = element.getBoundingClientRect().top - 90;
    window.scrollBy(0, scrollOffset);
  }
};

export const fetchUtmParamsInCookies = () => {
  let utmParamsInCookie;
  if (global?.window && global?.window?.getUTMParameters) {
    utmParamsInCookie = global.window.getUTMParameters();
  }
  return utmParamsInCookie?.utm_referral || '';
}

export const getPageSourceFromUrl = () => {
  const url = window.location.href;
  const urlMappings: any = {
    '/': 'homepage',
    '/fundraisers': 'fr_listing',
    '/fundraisers/children': 'cause_based_page',
    '/fundraisers/elderly-care': 'cause_based_page',
    '/fundraisers/animal-welfare': 'cause_based_page',
    '/fundraisers/health': 'cause_based_page',
    '/fundraisers/help-the-poor': 'cause_based_page',
    '/fundraisers/differently-abled': 'cause_based_page',
    '/fundraisers/women': 'cause_based_page',
    '/fundraisers/disaster-relief': 'cause_based_page',
    '/fundraisers/save-environment': 'cause_based_page',
    '/fundraisers/art-and-culture': 'cause_based_page',
    '/fundraisers/sports': 'cause_based_page',
    '/fundraisers/human-rights': 'cause_based_page',
    '/fundraisers/[^/]+': 'fr_page',   
    '/fundraisers/create/select': 'choose_purpose',
    '/fundraisers/create/program-listing': 'create_fundraiser',
    '/fundraisers/create/nonprofit/[^/]+': 'create_fundraiser',
    '/fundraisers/create/[^/]+': 'create_fundraiser',
    '/fundraisers/create/campaign/[^/]+': 'create_fundraiser',
    '/ngos/[^/]+': 'ngo_page',
    '/newsletters': 'newsletter',
  };

  const path = new URL(url).pathname;

  for (const key in urlMappings) {
    const regex = new RegExp('^' + key + '$');
    if (regex.test(path)) {
      return urlMappings[key];
    }
  }
  // If URL does not match any mapping, return a default value
  return null; // Return null if no match is found
};

// Subscription Date of transaction function
export const getNextPaymentDate = (paymentDay: number,lastPaymentDate: Date, months: number, tokenRecurringType: any = null) => {
  const now = new Date(lastPaymentDate);
  let month = (now.getMonth()+months)%12;
  let year = Math.floor((now.getMonth()+months)/12) + now.getFullYear();

  switch (true){
    case (month == 1 && paymentDay >=28) :
      paymentDay = 28;
      if(year%4===0 && (year % 100 !== 0 || year % 400 === 0))
        paymentDay = 29;
      break;
    case ((month == 3 || month == 5  || month == 8 
          || month == 10) && paymentDay ==31):
      paymentDay = 30;
      break;
    default:
      break;
  }

  // for before token recurring type in caw make next payment date 2 days before the actual date
  if(tokenRecurringType === 'before' && TOKEN_FREQUENCY_BEFORE_FOR_DATES.includes(paymentDay)){
    paymentDay = paymentDay - 2;
  }
  const nextPaymentDate = new Date(year, month, paymentDay); 
  
  /* for caw razorpay you cannot charge the autodebit on last day of month 
  so nextpayment date will be -1 day */
  const nextDayToNextPaymentDate = new Date(year, month, paymentDay);
  nextDayToNextPaymentDate.setDate(nextPaymentDate.getDate() + 1);
  if(nextDayToNextPaymentDate.getDate() ===1 && tokenRecurringType === 'after'){
      nextPaymentDate.setDate(nextPaymentDate.getDate() - 1);
  }
  
  return nextPaymentDate;
} 

  export const paymentDate = (frequency: string, createdAt: Date, resumedAt: Date, tokenType: any = null) => {
    let addMonth: number;
    let paymentDate = createdAt;
    if(frequency === 'MONTHLY') addMonth = 1;
    else if(frequency === 'QUARTERLY') addMonth = 3;
    else if(frequency === 'ANNUALLY') addMonth = 12;
    else addMonth = 1;
    if(resumedAt === null || resumedAt === undefined){
      resumedAt = new Date();
    }

    while(true){
      if(paymentDate < resumedAt ){
        paymentDate = new Date(paymentDate.getFullYear(),
          paymentDate.getMonth() + addMonth, paymentDate.getDate());
      }
      else break;
    }
    paymentDate = new Date(paymentDate.setMonth(paymentDate.getMonth() - addMonth));
    paymentDate = getNextPaymentDate(createdAt.getDate(),paymentDate,addMonth, tokenType);
    return paymentDate; 
  };

  export const getIntervalText = (subscriptionType: string | undefined) => {
    switch (subscriptionType) {
        case DONATION_TYPE_LIST.MONTHLY:
            return 'month';
        case DONATION_TYPE_LIST.QUARTERLY:
            return 'quarter';
        case DONATION_TYPE_LIST.ANNUALLY:
            return 'year';
        default:
            return '';
    }
  };
  // convert number to 10K, 10Lacks & 10Crore if less then 9999 then show default
  export const convertNumbertoKLCFormatter = (num: any) => {
    var val: any = `${(num)}`;
    if (val >= 10**7) {
      return Math.floor(val / 10**7) + "C";
    } else if (val >= 10**5) {
      return Math.floor(val / 10**5) + "L";
    } else if (val >= 10**4) {
      return Math.floor(val / 10**3) + "K";
    } else {
      // negative value then set to 0
      if(Math.sign(val) == -1){
        return 0
      }else{
        return val;
      }
    }
  }
