import React, { useReducer, useEffect } from "react";
import Analytics from "../utils/analytics";


import {
  initialState as toastInitialState,
  reducer as toastReducer,
} from "./reducers/toast";

import ToastMessage from "../components/common/ToastMessage/ToastMessage";

import {
  initialState as campaignInitialState,
  reducer as campaignReducer,
} from "./reducers/campaign";

import {
  initialState as EditCampaignInitialState,
  reducer as EditCampaignReducer,
} from "./reducers/editCampaign";

import {
  initialState as configInitialState,
  reducer as configReducer,
} from "./reducers/config";

import {
  initialState as userInitialState,
  reducer as userReducer,
} from "./reducers/user";

import {
  initialState as userIPInitialState,
  reducer as userIPReducer,
} from './reducers/userIP';

import {
  initialState as startFundraisersInitialState,
  reducer as startFundraisersReducer,
} from "./reducers/startFundraisers";

import {
  initialState as cancelReasonInitialState,
  reducer as cancelReasonReducer,
} from "./reducers/reason";

import {
  initialState as showSuccessDialogInitialState,
  reducer as showSuccessDialogReducer,
} from "./reducers/config";

import {
  initialState as shareCountInitialState,
  reducer as shareCountReducer,
} from "./reducers/campaign";

import {
  initialState as identifiersInitialState,
  reducer as identifiersReducer,
} from "./reducers/config";

import {
  initialState as dashboardInitialState,
  reducer as dashboardReducer,
} from "./reducers/dashboard";

import {
  NonProfitFundraiserTypes,
  initialState as NonProfitInitialState,
  reducer as nonProfitReducer,
} from "./reducers/nonprofit";

import { useLocation, useParams, useHistory } from "react-router-dom";
import { get, getSearch, noAuthGet, post, put } from "../utils/axiosClient";
import Context from "./Context";
import { loadScript } from '../utils/helpers'
import DBStorage from "../utils/DBStorage";
import { googleClientId, BASE_URL } from '../config'
import {
  generateTokenFromBackend,
  getGoogleContactsFromBackend
} from "../utils/GoogleAuth";
import axios from "axios";
import { prepareNonProfitDetailsObj, prepareRecentSupportesObj, prepareSimilarNGOList } from "../utils/nonProfitProfileHelpers";
import { causeNameSlugList } from "../constant";

const ContextProvider = Context.Provider;

interface contextProviderProp {
  children: any;
}

declare const window: Window &
  typeof globalThis & {
    gapi: any;
    GoogleAuth: any;
    google: any;
  }

const ContextProviderWrapper: React.FunctionComponent<contextProviderProp> = ({
  children,
}) => {
  const history: any = useHistory();
  let location: any = useLocation();
  let slugName = location.pathname;

  if (slugName.includes("/fundraisers/")) slugName = slugName.replace("/fundraisers/", "")
  if (slugName.includes("edit/")) slugName = slugName.replace("edit/", "");
  else if (slugName.includes("/jointeam/")) slugName = slugName.split('/jointeam/')[0].replace('/', '');
  else if (slugName.includes("pending-tasks/")) slugName = slugName.replace("pending-tasks/", "");
  else if (slugName.includes("dashboard/")) slugName = slugName.split('/')[1];
  else if (!slugName.includes("create/")) slugName = slugName.replace("/", "");

  const [toastData, toastDispatch] = useReducer(
    toastReducer,
    toastInitialState
  );

  const [nonProfitData, nonProfitDispatch] = useReducer(
    nonProfitReducer,
    NonProfitInitialState
  )

  const [campaignData, campaignDispatch] = useReducer(
    campaignReducer,
    campaignInitialState,
    () => {
      if (typeof window === "object") {
        const localData = DBStorage.get(
          `fundraiserData-campaignData-${slugName}`,
          false
        );
        return localData ? JSON.parse(localData) : {};
      }
    }
  );

  const [EditCampaignData, EditCampaignDispatch] = useReducer(
    EditCampaignReducer,
    EditCampaignInitialState
  );

  const [configData, configDispatch] = useReducer(
    configReducer,
    configInitialState
  );
  const [userData, userDispatch] = useReducer(userReducer, userInitialState);
  const [userIP, userIPDispatch] = useReducer(userIPReducer, userIPInitialState);

  const [cancelReason, cancelReasonDispatch] = useReducer(cancelReasonReducer, cancelReasonInitialState)

  const [startFundraisersData, startFundraisersDispatch] = useReducer(
    startFundraisersReducer,
    startFundraisersInitialState
  );
  const [showSuccessDialogData, showSuccessDialogDispatch] = useReducer(
    showSuccessDialogReducer,
    showSuccessDialogInitialState
  );

  const [shareCountData, shareCountDispatch] = useReducer(
    shareCountReducer,
    shareCountInitialState
  );

  const [dashboardData, dashboardDispatch] = useReducer(
    dashboardReducer,
    dashboardInitialState
  );

  const getIPData = async () => {
    const ipResponse = await axios.get("https://geolocation-db.com/json/");
    userIPDispatch({
      type: 'user-ip',
      value: ipResponse.data
    });
  };

  const getConfigDetails = async () => {
    get("config/").then((res) => {
      let data = res.data.currencies.sort((a: any, b: any) =>
        a.currency_code > b.currency_code
          ? 1
          : b.currency_code > a.currency_code
            ? -1
            : 0
      );
      //Filter only the INR and USD item
      let filteredData = data.filter((item: any, index: number) => {
        return item?.currency_code == "USD" || item?.currency_code == "INR"
      })
      if (res.data?.defaults) DBStorage.set('defaultCountryCode', res.data?.defaults?.country_code)
      configDispatch({
        type: "goodwill_badges",
        value: res.data.rewards.goodwill_badges,
      });
      configDispatch({
        type: "banners",
        value: res.data.monthly_campaign_banner,
      });
      configDispatch({
        type: "currency-list-data",
        value: filteredData,
      });
      configDispatch({
        type: "homepage-data",
        value: res.data.homepage,
      });
      configDispatch({
        type: "causes-list",
        value: res.data.causes,
      });
      configDispatch({
        type: "currency-default-value",
        value: res.data.defaults,
      });
      configDispatch({
        type: "give-india-faqs-data",
        value: res.data.faqs,
      });
      configDispatch({
        type: "campaign-template-data",
        value: res.data.campaign_templates,
      });
      configDispatch({
        type: "pincodes",
        value: res.data.pincodes,
      });
      configDispatch({
        type: "countries-data",
        value: res.data.countries,
      });
      configDispatch({
        type: "spotlight",
        value: res.data.spotlight,
      });
      configDispatch({
        type: "fundraiser-locations",
        value: res.data['fundraiser-locations'],
      });
      configDispatch({
        type: "nudge",
        value: res.data['nudge'],
      });
    });
  };

  const [identifiersData, identifiersDispatch] = useReducer(
    identifiersReducer,
    identifiersInitialState,
    () => {
      if (typeof window === "object") {
        const localData = localStorage.getItem(
          `fundraisers-identifiersData${slugName}`
        );
        return localData ? JSON.parse(localData) : {};
      }
    }
  );

  const getCampaignDetails = async () => {
    if (!slugName.includes("create/") && !slugName.includes("find-ngo-by-area") && !slugName.includes("monthly-missions")) {
      await get(`campaigns/${slugName}`)
        .then((res) => {
          if (res.status === 200) {
            configDispatch({
              type: "identifiers-slug-data",
              value: res.data.data.slug,
            });
            if (slugName !== res.data.data.slug) {
              const locationSearch = location.search;
              history.replace({
                pathname: `/fundraisers/${res.data.data.slug}`,
                search: locationSearch,
              });
            }
            campaignDispatch({
              type: "campaign-data",
              value: res.data.data,
            });
            campaignDispatch({
              type: "campaign-loaded",
              value: true,
            });
          }
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            if (window) {
              window.location.href = `/404`;
            }
          } else {
            toastDispatch({
              type: "new-toast",
              value: {
                open: true,
                severity: "warning",
                message:
                  "Fundraiser details did not load properly. Please refresh the page once again.",
                seconds: 3000,
              },
            });
          }
        });
    }
  };

  const getUserFundraisers = async (params?: any) => {
    await get(`campaigns/`,
      params
    )
      .then((res) => {
        if (res) {
          campaignDispatch({
            type: "get-user-fundraisers",
            value: {
              userFundraisers: res.data.data,
              count: res.data.count,
            }
          })
          campaignDispatch({
            type: "fundraiser-loaded",
            value: true,
          });
        }
      }).catch((err) => {
        campaignDispatch({
          type: "fundraiser-loaded",
          value: false,
        });
      })
  }

  const getUserDonations = async (beforeDate?: any) => {
    await get(`donations/`, {
      filter_by_user_id: userData?.userData?._id,
      filter_by_status: ["CONFIRMED", "DISBURSED", "SETTLED"],
      limit: 10,
      before: beforeDate,
    })
      .then((res) => {
        if (res) {
          campaignDispatch({
            type: "get-user-donations",
            value: res.data.supporters,
          })
          campaignDispatch({
            type: "donations-loaded",
            value: true,
          });

        }
      }).catch((err) => {
        campaignDispatch({
          type: "donations-loaded",
          value: false,
        });
      })
  }

  const getUserMonthlySubscription = async () => {
    await get(`subscriptions/profile/`, {
      subscription_type: "RECURRING",
      limit:100
    })
      .then((res) => {
        if (res) {
          campaignDispatch({
            type: "get-user-monthly-subscription",
            value: res?.data?.subscriptions,
          });
          campaignDispatch({
            type: "monthly-subscriptions-loaded",
            value: true,
          });
        }
      }).catch((err) => {
        campaignDispatch({
          type: "monthly-subscriptions-loaded",
          value: false,
        });
      })
  }

  const getUserDetails = () => {
    get(`login/me`)
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data?.data?.token) DBStorage.set('token', res.data?.data?.token)
          const googleToken = res.data.data.user.google_token;
          if (googleToken === null || googleToken.length === 0) {
            userDispatch({
              type: "set-has-google-token",
              value: false,
            });
          } else {
            userDispatch({
              type: "set-has-google-token",
              value: true,
            });
          }
          userDispatch({
            type: "user-data",
            value: res.data.data.user,
          });

        }
      })
      .catch((err) => {
        userDispatch({
          type: "user-data",
          value: {},
        });
      });
  };

  const logoutUser = () => {
    try {
      Analytics.logout(userData?.userData?._id)
    }
    catch (e) {
      console.debug('Analytics: Logout event not captured');
    }
    return post('login/unauthorize', {})
      .then(res => {
        if (res.data.status === 200) {
          userDispatch({
            type: "user-data",
            value: {}
          })
          DBStorage.remove('token');
        }
        return true
      })
      .catch(error => {
        userDispatch({
          type: "user-data",
          value: {},
        });
        return false
      })
  }

  const updateUserDetails = async (userDetails: any) => {
    post(`login/me`, userDetails)
      .then((res) => {
        if (res.data.status === 200) {
          userDispatch({
            type: "user-data",
            value: res.data.data.user,
          });
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "success",
              message:
                "Updated your details successfully",
              seconds: 3000,
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "error",
              message:
                "Could not update your profile details. Please try again",
              seconds: 3000,
            },
          });
        }
      })
  }

  const updateDonationNameAndPAN = async (orderId: string, updatedDetails: any, donations: any) => {
    post(`donations/${orderId}/update-pan`, {
      name: updatedDetails.name,
      pan_card: updatedDetails.pan,
      source: 'profile'
    }).then((res: any) => {
      let { pan_card } = res?.data?.donation?.user;
      let data = donations.map((donation: any) => {
        if (donation.order_id === orderId) {
          donation.user.pan_card = pan_card;
          donation.additional_fields.pan_and_name_update_count = res?.data?.donation?.additional_fields?.pan_and_name_update_count
          donation.receipt_uri = res?.data?.donation?.receipt_uri || null
          donation.reciept_reason = res?.data?.donation?.reciept_reason || null;
          return donation;
        }
        return donation;
      });
      campaignDispatch({
        type: "get-user-donations",
        value: [...data],
      });
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "success",
          message:
            "Updated donation successfully",
          seconds: 3000,
        },
      });
    })
      .catch((err: any) => {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message:
              "API error. Could not update your PAN details. Please try again",
            seconds: 3000,
          },
        });
      })
  }

  const updateMultipleDonationAnonymousStatus = async (orderIds: [String], anonymousStatus: boolean, donations: any) => {
    put(`donations/anonymous/update`, {
      donationIds: orderIds,
      is_anonymous: anonymousStatus
    }).then((res: any) => {
      getUserMonthlySubscription()
      campaignDispatch({
        type: "get-user-donations",
        value: [],
      });
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "success",
          message:
            "Updated donation successfully",
          seconds: 3000,
        },
      });
    })
      .catch((err: any) => {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message:
              "API error. Could not update donation. Please try again",
            seconds: 3000,
          },
        });
      })
  }

  const updateDonationAnonymousStatus = async (orderId: String, anonymousStatus: boolean, donations: any) => {
    post(`donations/${orderId}`, {
      is_anonymous: anonymousStatus
    }).then((res: any) => {
      let data = donations.map((donation: any) => {
        if (donation.order_id === orderId) {
          donation['is_anonymous'] = res.data.donation.is_anonymous;
        }
        return donation;
      });
      campaignDispatch({
        type: "get-user-donations",
        value: [...data],
      });
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "success",
          message:
            "Updated donation successfully",
          seconds: 3000,
        },
      });
    })
      .catch((err: any) => {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message:
              "API error. Could not update donation. Please try again",
            seconds: 3000,
          },
        });
      })
  }

  const getUserGiftCards = async () => {
    await get(`links/gift-cards/user/get-cards`)
      .then((res) => {
        if (res) {
          userDispatch({
            type: "gift-cards",
            value: res.data.data
          })
        }
      })
      .catch((err) => {
        userDispatch({
          type: "gift-cards",
          value: false
        })
      })
  }

  const handleCurrencyChange = (event: any) => {
    let value: any = typeof event !== "object" ? event : event.target.value;
    let selectedCurrency: any = {};
    selectedCurrency = configData.currency.currencyList[value];
    let currencyData = {
      currencyExchangeData: selectedCurrency?.exchange_rate,
      currencySymbolData: selectedCurrency?.currency_symbol,
      currencyCodeData: selectedCurrency?.currency_code,
      currencyIndexData: value,
    };
    configDispatch({
      type: "update-currency-data",
      value: currencyData,
    });
    DBStorage.set("currencyCode", selectedCurrency.currency_code);
    DBStorage.set("currencyExchangeCode", currencyData.currencyExchangeData);
  };
  const handleCurrencySwitch = (check: boolean) => {
    let currecyList = configData.currency.currencyList
    const usdIndex = currecyList.findIndex((object: any) => {
      return object.currency_code === 'USD';
    });
    const inrIndex = currecyList.findIndex((object: any) => {
      return object.currency_code === 'INR';
    });
    let value = check ? usdIndex : inrIndex
    let selectedCurrency: any = {};
    selectedCurrency = configData.currency.currencyList[value];
    let currencyData = {
      currencyExchangeData: selectedCurrency.exchange_rate,
      currencySymbolData: selectedCurrency.currency_symbol,
      currencyCodeData: selectedCurrency.currency_code,
      currencyIndexData: value,
    };
    configDispatch({
      type: "update-currency-data",
      value: currencyData,
    });
    DBStorage.set("currencyCode", selectedCurrency.currency_code);
    DBStorage.set("currencyExchangeCode", currencyData.currencyExchangeData);
  };

  const getTopDonors = (campaignID: string) => {
    noAuthGet(`donations/`, {
      sort: "-amount_in_inr",
      filter_by_campaign: campaignID,
      filter_by_status: ["CONFIRMED", "DISBURSED", "SETTLED"],
      limit: 10
    })
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.supporters;
          let donorData = data.map((item: any, idx: number) => {
            if (idx == 0) {
              item['type'] = 'Top'
              item['bgcolor'] = 'rgba(111, 207, 151, 0.25)'
              return item
            }
            return item
          })
          campaignDispatch({
            type: "top-donors-data",
            value: donorData
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "warning",
              message:
                "Donors list did not load properly. Please refresh to get the details again.",
              seconds: 3000,
            },
          });
        }
      });
  };

  const getRecentDonations = (campaignID: string) => { // all donations
    noAuthGet(`donations/`, {
      filter_by_campaign: campaignID,
      filter_by_status: ["CONFIRMED", "DISBURSED", "SETTLED"],
      limit: 10,
    })
      .then((res) => {
        if (res.status === 200) {
          campaignDispatch({
            type: "recent-donors-data",
            value: res?.data?.supporters,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "warning",
              message:
                "Donors list did not load properly. Please refresh to get the details again.",
              seconds: 3000,
            },
          });
        }
      });
  };

  const getNonProfitDetails = (nonprofitSlug: String) => {
    get(`nonprofits/${nonprofitSlug}`)
      .then((res) => {
        startFundraisersDispatch({
          type: "single-nonprofit-data",
          value: res.data,
        })
      })
      .catch((err) => {
        let errMsg = '';
        if (err && err.response && err.response.status) errMsg = 'Please Login to continue'
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message: errMsg ? errMsg : "Request failed! Please try again",
            seconds: 3000,
          },
        });
      });
  };
  const getCreateCampaignDetails = (cSlug: String) => {
    get(`campaigns/${cSlug}`).then((res) => {
      startFundraisersDispatch({
        type: "single-campaign-data",
        value: res.data,
      });
    })
      .catch((err) => {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message: "Request failed! Please try again",
            seconds: 3000,
          },
        });
      });
  };

  const getProjectFAQs = (projectID: string) => {
    noAuthGet(`projects/${projectID}`)
      .then((res) => {
        if (res.status === 200) {
          campaignDispatch({
            type: "project-faqs-data",
            value: res.data.faqs,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.status !== 404) {
            toastDispatch({
              type: "new-toast",
              value: {
                open: true,
                severity: "warning",
                message:
                  "FAQs list did load properly. Please refresh to get the details again.",
                seconds: 3000,
              },
            });
          }
        }
      });
  };

  const getShareCount = (socialMedia: any, url: string) => {
    post("links/surl/getCount", {
      url,
      type: socialMedia,
    }).then((res) => {
      if (res.status === 200) {
        campaignDispatch({
          type: socialMedia ? `get-${socialMedia}-share` : "get-share",
          value: res.data.data,
        });
      }
    });
  };

  const getSupportingCampaigns = (identifiersID: string) => {
    get(`campaigns/${identifiersID}/supporting-campaigns`).then((res) => {
      campaignDispatch({
        type: "supporting-campaigns-data",
        value: res.data.data,
      });
    });
  };

  const getCampaginUpdates = (identifiersID: string) => {
    get(`updates/${identifiersID}`, {
      limit: 2,
    })
      .then((res) => {
        if (res.status === 200) {
          campaignDispatch({
            type: "campaign-updates-data",
            value: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "warning",
              message:
                "List of Updates did load properly. Please refresh to get the details again.",
              seconds: 3000,
            },
          });
        }
      });
  };

  const getProjectUpdates = (projectID: string) => {
    get(`updates/${projectID}`, {
      limit: 2,
    })
      .then((res) => {
        if (res.status === 200) {
          campaignDispatch({
            type: "project-updates-data",
            value: res.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "warning",
              message:
                "List of Updates did load properly. Please refresh to get the details again.",
              seconds: 3000,
            },
          });
        }
      });
  };

  let client: any;
  const loadSocialApi = async () => {
    await loadScript('//accounts.google.com/gsi/client').then(function () {
      if (window && window.google) {
        client = window.google.accounts.oauth2.initCodeClient({
          client_id: googleClientId,
          scope: 'profile email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly',
          ux_mode: 'popup',
        });
      }
    }).catch(function (err) {
      console.log(err);
    });
  }

  const getContactFromGoogle = async () => {

    let client: any;
    let access_token: any;

    if (window && window.google) {
      client = await window.google.accounts.oauth2.initTokenClient({
        client_id: googleClientId,
        scope: 'profile email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/directory.readonly',
        callback: (response: any) => {
          access_token = response.access_token;
        },
      });
      client.callback = async (resp: any) => {
        const generateContacts: any = await getGoogleContactsFromBackend(resp.access_token);
        if (generateContacts.data) {
          dashboardDispatch({
            type: "set-import-source",
            value: `google:${generateContacts.source}`,
          });
          dashboardDispatch({
            type: 'add-csv-data',
            value: generateContacts.data
          })
        } else {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "error",
              message: "Error While Trying to Fetch Contacts",
              seconds: 3000,
            },
          });
        }
        getUserDetails();
      }
    };
    //Requesting token
    client.requestAccessToken({ prompt: 'consent' });
  }

  // const getBanners = async () => {
  //   get("config/monthly_campaign_banner")
  //     .then((res) => {
  //       console.log(res);
  //       configDispatch({
  //         type: "banners",
  //         value: res.data,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       toastDispatch({
  //         type: "new-toast",
  //         value: {
  //           open: true,
  //           severity: "warning",
  //           message: "Failed to load Banners.",
  //           seconds: 3000,
  //         },
  //       });
  //     })
  // }

  const connectGoogleAccount = async (data: any) => {
    var client: any;
    var code: any;
    client = await window.google.accounts.oauth2.initCodeClient({
      client_id: googleClientId,
      access_type: 'offline',
      ux_mode: 'popup',
      scope: 'profile email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/userinfo.email',
      callback: (response: any) => {
        code = response.code
      },
    });
    client.callback = async (resp: any) => {
      const generateToken = await generateTokenFromBackend(resp.code, false);
      if (generateToken) {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "success",
            message: "Google Account Connected Successfully",
            seconds: 3000,
          },
        });
      } else {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message: "Error while connecting to Google account or insufficient permissions. Please try again.",
            seconds: 3000,
          },
        });
      }
      getUserDetails();
    };
    // requesting access token
    client.requestCode();
  }


  const setCSVData = (blob: any) => {
    // Create a form and send the data
    let formData = new FormData();
    formData.append('contacts', blob);

    post(`contacts/parse`, formData).then((res: any) => {
      let arrayData = res.data.data;
      arrayData.forEach((l: any, index: any) => {
        arrayData[index] = { ...l, selected: true }
      });
      dashboardDispatch({
        type: 'add-csv-data',
        value: arrayData
      })
    }).catch((err: any) => {
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "error",
          message: "Error in File Format",
          seconds: 3000,
        },
      });
    })
  };


  const handleContactUpload = (evt: any) => {
    evt.preventDefault();
    if (window?.FileReader) {
      let file = evt.target.files[0];
      if (/\.(csv)$/i.test(file.name)) {
        setCSVData(file)
      } else if ((/\.(zip)$/i.test(file.name))) {
        setCSVData(file)
      } else {
        //Wrong file type
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message: "File type should be .csv",
            seconds: 3000,
          },
        });
        // this.props.setError('File type should be .csv or .vcf');
      }

    } else {
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "error",
          message: "Browser not supported!!!",
          seconds: 3000,
        },
      });
      // Display error
      // this.props.setError('');
    }
  }

  const setImportedContacts = (selected: any, source?: any) => {
    let reqData = {
      importerUserId: userData.userData._id,
      contacts: selected,
      source: source ? source : dashboardData?.importSource,
      sourceImporterId: null
    }
    post(`contacts/`, reqData).then((res: any) => {
      let message: String;
      let status: String;
      if (selected.length === res.data.data.failed && selected.length === 1) {
        message = 'Failed to add the contact';
        status = 'error';
      } else if (selected.length === res.data.data.processed && selected.length === 1) {
        message = `Contact has been added successfully`;
        status = 'success';
      } else if (selected.length === res.data.data.failed) {
        message = 'Unable to add the contact due to error in processing';
        status = 'error';
      } else if (selected.length === res.data.data.processed) {
        message = `Contacts has been added successfully`;
        status = 'success';
      } else {
        message = `${res.data.data.processed}/${selected.length} contacts added successfully. Failed to add ${res.data.data.failed} contacts`;
        status = 'success';
      }
      dashboardDispatch({
        type: 'add-csv-data',
        value: []
      })
      dashboardDispatch({
        type: 'set-import-source',
        value: ""
      })
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: status,
          message: message,
          seconds: 4000,
        },
      });

    }).catch((err: any) => {
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "error",
          message: "Unable to add the contact since Server is not reachable. Please try again!",
          seconds: 3000,
        },
      });
    })
  }


  const sendMail = async (type: any, data: any) => {
    let url: string = "";
    let reqData: any;
    let { text, subject, emails, from } = data;

    if (type === 'invite') {
      url = "contacts/email/invite/send/";
      reqData = {
        campaign_id: campaignData?.campaignData?._id,
        subject,
        text,
        emails: emails.map(function (l: any) {
          return {
            email: l.email,
            name: l.name,
          };
        }),
        from: from
      };
    } else if (type === "thank") {
      url = "contacts/email/thank/send/";
      reqData = {
        campaign_id: campaignData?.campaignData?._id,
        subject,
        text,
        emails: emails.map(function (l: any) {
          return {
            email: l.email,
            name: l.name,
          };
        }),
        from: from
      };
    } else if (type === "remind") {
      url = "contacts/email/invite/remind/";
      reqData = {
        campaign_id: campaignData?.campaignData?._id,
        subject,
        text,
        emails: emails.map(function (l: any) {
          return {
            email: l.email,
            name: l.name,
          };
        }),
        from: from
      };
    } else if (type === "thank-to-all") {
      url = "contacts/email/thank/send-to-all/";
      reqData = {
        campaign_id: campaignData?.campaignData?._id,
        from: from
      };
    }

    await post(url, reqData)
      .then((res: any) => {
        if (res.status === 201) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "success",
              message: "Request has been sent Successfully",
              seconds: 3000,
            },
          });
        }
      })
      .catch((err) => {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "error",
            message: "Request failed! Please try again",
            seconds: 3000,
          },
        });
      });

    getCampaignDetails();
  };

  const getNonProfitProfile = async (nonProfitName: string) => {
    noAuthGet(`nonprofits/${nonProfitName}`)
      .then((res) => {
        const nonProfitDetails = prepareNonProfitDetailsObj(res);
        nonProfitDispatch({
          type: "add-nonprofit-details",
          value: nonProfitDetails,
        })
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          if (window) {
            window.location.href = `/404`;
          }
        } else {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "error",
              message: "Could not fetch Nonprofit Details! Please try again",
              seconds: 3000,
            },
          });
        }
      });
  };

  const getNonProfitRecentSupporters = async (nonProfitId: string) => {
    noAuthGet("donations", {
      filter_by_nonprofit: nonProfitId,
      filter_by_status: ["CONFIRMED", "DISBURSED", "SETTLED"],
      limit: 3
    })
      .then((response) => {
        if (response.status === 200) {
          const recentSupporters = prepareRecentSupportesObj(response);
          nonProfitDispatch({
            type: "add-recent-supporters",
            value: recentSupporters,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "warning",
              message:
                "Failed to fetch recent supporters list.",
              seconds: 3000,
            },
          });
        }
      })
  }

  const getSimilarNgos = async (causes: string[], nonProfitId: string) => {
    const searchParam = {
      fq: `(type:nonprofit AND is_active:(true) AND -id:(${nonProfitId}) ${causes.length > 0 ? `AND causes:(${causes.join(' OR ')})` : ``}  )`,
      sort: `recent_supporter_count desc`,
      rows: 5,
      start: 0
    }
    getSearch('search', searchParam)
      .then((response) => {
        if (response.status === 200) {
          const similarNgos = prepareSimilarNGOList(response.data.response);
          nonProfitDispatch({
            type: "add-similar-ngos",
            value: similarNgos
          })
        }
      })
      .catch((err) => {
        toastDispatch({
          type: "new-toast",
          value: {
            open: true,
            severity: "warning",
            message:
              "Failed to fetch similar NGOs.",
            seconds: 3000,
          },
        });
      })
  }

  const getNgoPopularFundraiserIds = async (nonProfitId: string) => {
    const searchParams = {
      fq: `(type:campaign AND nonprofit_id:"${nonProfitId}" AND is_primary:true AND is_active:true) `, //AND raised_amount_in_inr:[1 TO *]
      fl: 'id',
      rows: 5000,
      start: 0
    }
    const result = await getSearch("search", searchParams);
    if (result.status === 200) {
      const fundraiserIds = result.data.response.docs.map((doc: any) => doc.id);
      nonProfitDispatch({
        type: "add-nonprofit-popular-fr-ids",
        value: fundraiserIds,
      })
    } else {
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "error",
          message: "Could not fetch Nonprofit Popular Fundraiser Ids!",
          seconds: 3000,
        },
      });
    }
  }

  const getNgoPopularFundraisers = async (nonProfitId: string, rows: number, start: number) => {
    const searchParams = {
      fq: `(type:campaign AND nonprofit_id:"${nonProfitId}" AND is_primary:true AND is_active:true) `, //AND raised_amount_in_inr:[1 TO *]
      rows: rows,
      start: start,
      sort: `promoted_fundraiser desc,supporter_count desc, raised_amount_in_inr desc`
    }
    const result = await getSearch("search", searchParams);
    if (result.status === 200) {
      nonProfitDispatch({
        type: "add-nonprofit-popular-frs",
        value: result.data.response.docs,
      })
      nonProfitDispatch({
        type: "add-nonprofit-popular-fr-count",
        value: result.data.response.numFound,
      })
      nonProfitDispatch({
        type: "add-nonprofit-is-popular-fr-loaded",
        value: true,
      })
    } else {
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "error",
          message: "Could not fetch Nonprofit Popular Fundraisers!",
          seconds: 3000,
        },
      });
    }
  }

  const getNgoOtherFundraisers = async (nonProfitId: string, popularfundraiserIds: string[], rows: number, start: number) => {
    const frIdsStr = popularfundraiserIds.reduce((ids, currentValue, currentIndex) => {
      if (currentIndex === 0) {
        return `"${currentValue}"`
      } else {
        return `${ids}, "${currentValue}"`
      }
    }, '')
    const searchParams = {
      fq: `(type:campaign AND nonprofit_id:"${nonProfitId}")`,
      sort: `promoted_fundraiser desc,supporter_count desc, raised_amount_in_inr desc`,
      rows: rows,
      start: start
    }
    if (frIdsStr) {
      searchParams['fq'] = `(type:campaign AND nonprofit_id:"${nonProfitId}" AND NOT id:(${frIdsStr}) )`;
    }
    const result = await getSearch("search", searchParams);
    if (result.status === 200) {
      nonProfitDispatch({
        type: "add-nonprofit-other-frs",
        value: result.data.response.docs,
      })
      nonProfitDispatch({
        type: "add-nonprofit-other-fr-count",
        value: result.data.response.numFound,
      })
      nonProfitDispatch({
        type: "add-nonprofit-is-other-fr-loaded",
        value: true,
      })
    } else {
      toastDispatch({
        type: "new-toast",
        value: {
          open: true,
          severity: "error",
          message: "Could not fetch Nonprofit Popular Fundraisers!",
          seconds: 3000,
        },
      });
    }
  }

  const getAllCauses = () => {
    noAuthGet("config/causes")
      .then((response) => {
        if (response.status === 200) {
          const allCauses = response.data.map((res: any) => res.name)
          nonProfitDispatch({
            type: "add-all-causes",
            value: allCauses
          })
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const store = {
    Toast: { state: toastData, dispatch: toastDispatch },
    Campaign: {
      state: campaignData,
      dispatch: campaignDispatch,
      fetchCampaignDetails: getCampaignDetails,
      getUserFundraisers: getUserFundraisers,
      getUserDonations: getUserDonations,
      updateDonationNameAndPAN: updateDonationNameAndPAN,
      updateDonationAnonymousStatus: updateDonationAnonymousStatus,
      updateMultipleDonationAnonymousStatus: updateMultipleDonationAnonymousStatus,
      getUserMonthlySubscription: getUserMonthlySubscription,
    },
    EditCampaign: { state: EditCampaignData, dispatch: EditCampaignDispatch },
    Config: { state: configData, dispatch: configDispatch },
    User: {
      state: userData,
      dispatch: userDispatch,
      getUser: getUserDetails,
      logout: logoutUser,
      updateUser: updateUserDetails,
      getUserGiftCards: getUserGiftCards
    },
    cancelReason: {
      state: cancelReason,
      dispatch: cancelReasonDispatch
    },
    onCurrencyChange: handleCurrencyChange,
    onCurrencyChangeSwitch: handleCurrencySwitch,
    StartFundraisers: {
      state: startFundraisersData,
      dispatch: startFundraisersDispatch,
    },
    ShowSuccessDialog: {
      state: showSuccessDialogData,
      dispatch: showSuccessDialogDispatch,
    },
    ShareCount: { state: shareCountData, dispatch: shareCountDispatch },
    Identifiers: { state: identifiersData, dispatch: identifiersDispatch },
    Dashboard: { state: dashboardData, dispatch: dashboardDispatch },
    NonProfit: {
      state: nonProfitData,
      dispatch: nonProfitDispatch,
      getProfile: getNonProfitProfile,
      getRecentSupporters: getNonProfitRecentSupporters,
      getSimilarNgos: getSimilarNgos,
      getPopularFundraisers: getNgoPopularFundraisers,
      getPopularFundraiserIds: getNgoPopularFundraiserIds,
      getOtherFundraisers: getNgoOtherFundraisers,
      getAllCauses: getAllCauses,
    },
    sendMail,
    contactFunc: {
      getGoogleContacts: getContactFromGoogle,
      getGoogleAccount: connectGoogleAccount,
      importContact: handleContactUpload,
      setContact: setImportedContacts
    },
    userIPContext: { state: userIP }
  };

  const getAllDonorInfo = (campaignID: string) => {
    noAuthGet(`donations/`, {
      filter_by_status: ["CONFIRMED", "DISBURSED", "SETTLED"],
      filter_by_campaign_donation_stats: campaignID
    }).then(res => {
      if (res?.status == 200 && res?.data) {
        let resp = res?.data
        let donorInfo = [];
        if (resp?.recent_donor && resp?.first_donor && resp?.top_donor) {
          let recent = { ...resp.recent_donor, type: 'Recent', bgcolor: 'rgba(242, 201, 76, 0.25)' }
          let first = { ...resp.first_donor, type: 'First', bgcolor: '#EEF4FF' }
          let top = { ...resp.top_donor, type: 'Top', bgcolor: 'rgba(111, 207, 151, 0.25)' }
          donorInfo = [recent, first, top]
          campaignDispatch({
            type: "donor-info",
            value: donorInfo
          });
        }
        else {
          campaignDispatch({
            type: "donor-info",
            value: []
          });
        }
        campaignDispatch({
          type: "live-donor-count",
          value: resp?.live_donor_count
        })
      }
    }).catch((err) => {
      if (err.response) {
        if (err.response.data.status !== 404) {
          toastDispatch({
            type: "new-toast",
            value: {
              open: true,
              severity: "warning",
              message:
                "Donor list did load properly. Please refresh to get the details again.",
              seconds: 3000,
            },
          });
        }
      }
      console.log(err);
    });
  }

  useEffect(() => {
    getUserDetails();
    getConfigDetails();
    getIPData();

    if (slugName !== "" &&
      slugName !== "fundraisers" &&
      slugName !== "profile" &&
      slugName !== "aboutus" &&
      slugName !== "careers" &&
      slugName !== "aboutus/" &&
      slugName !== "careers/" &&
      slugName !== "newsletters" &&
      slugName !== "myprofile" &&
      slugName !== "mydonations" &&
      slugName !== "myfundraisers" &&
      slugName !== "mysupport" &&
      slugName !== 'partner' &&
      slugName !== 'claim-gift-cards' &&
      !causeNameSlugList.includes(slugName) &&
      slugName !== 'spotlight' &&
      !slugName.includes('spotlight/') &&
      (slugName.search("ngos/") == -1)) {

      getCampaignDetails();
    }

    if (slugName.includes('create/nonprofit/')) {
      let nSlug = slugName.split('create/nonprofit/')[1];
      getNonProfitDetails(nSlug);
    }
    if (slugName.includes('create/campaign/')) {
      let cSlug = slugName.split('create/campaign/')[1];
      getCreateCampaignDetails(cSlug);
    }
    loadSocialApi()
  }, [slugName]);

  useEffect(() => {
    if (campaignData?.campaignData?._id && !location.pathname.includes("/pending-tasks/") && !location.pathname.includes("/dashboard/")) {
      getAllDonorInfo(campaignData?.campaignData?.id)
      getRecentDonations(campaignData?.campaignData?.id);
      getTopDonors(campaignData?.campaignData?.id);
      getSupportingCampaigns(campaignData?.campaignData?.id);
      getCampaginUpdates(campaignData?.campaignData?.id);

    }
  }, [campaignData?.campaignData?._id]);

  useEffect(() => {
    if (
      campaignData?.campaignData?.project &&
      campaignData?.campaignData?.project._id && !location.pathname.includes("/pending-tasks/") &&
      !location.pathname.includes("/dashboard/")
    ) {
      getProjectFAQs(campaignData?.campaignData?.project._id);
      getProjectUpdates(campaignData?.campaignData?.project._id);
    }
  }, [campaignData?.campaignData?.project]);

  useEffect(() => {
    if (campaignData?.campaignData?.canonical_url && !location.pathname.includes("/dashboard/") && !location.pathname.includes("/pending-tasks/")) {
      getShareCount("", campaignData?.campaignData?.canonical_url);
      // getShareCount("whatsapp", campaignData?.campaignData?.canonical_url);
      // getShareCount("facebook", campaignData?.campaignData?.canonical_url);
    }
  }, [campaignData?.campaignData?.canonical_url]);

  return (
    <ContextProvider value={store}>
      {children}
      <ToastMessage
        open={toastData.open}
        message={toastData.message}
        severity={toastData.severity}
        seconds={toastData.seconds}
        handleClose={() => toastDispatch({ type: "close", value: "" })}
      />
    </ContextProvider>
  );
};

export default ContextProviderWrapper;