import * as interfaces from "../interfaces";

export const initialState: interfaces.campaign = {
  campaignData: {},
  isCampaignLoaded: false,
  userFundraisersData: {
    userFundraisers: [],
    count: null,
  },
  userDonations: {},
  isFundraiserLoaded: false,
  isDonationsLoaded: false,
  donors: {
    donationUserActivityInfo: [],
    liveDonorCount: null
  },
  projectFaqs: [],
  sharesData:{
    shareCount:0,
    whatsappShareCount: 0,
    facebookShareCount:0
  },
  supportingCampaigns: [],
  updates:{},
};

export const reducer = (state: any, action: interfaces.action) => {

  switch (action?.type) {
    case "campaign-data":
      return { ...state, campaignData: action.value };
    case "campaign-loaded":
      return { ...state, isCampaignLoaded: true };
    case "get-user-fundraisers":
      return { ...state, userFundraisersData: action.value };
    case "fundraiser-loaded":
      return { ...state, isFundraiserLoaded: true };
    case "get-user-donations":
      return { ...state, userDonations: action.value };
    case "donations-loaded":
      return { ...state, isDonationsLoaded: true };
    case "get-user-monthly-subscription":
      return { ...state, monthlyDonations: action.value };
    case "monthly-subscriptions-loaded":
      return { ...state, isMonthlyDonationsLoded: action.value };
    case "top-donors-data":
      return { ...state, donors: { ...state.donors, topDonors: action.value } };
    case "recent-donors-data":
      return { ...state, donors: { ...state.donors, recentDonors: action.value } };
    case "project-faqs-data":
      return { ...state, projectFaqs: action.value };
    case "closed-campaign-data":
      return { ...state, campaignData : {...state.campaignData, is_closed: action.value } };
    case "get-share":
      return { ...state, sharesData: {...state.sharesData, shareCount: action.value } };
    case "get-whatsapp-share":
      return { ...state, sharesData: {...state.sharesData, whatsappShareCount: action.value } };
    case "get-facebook-share":
      return { ...state, sharesData: {...state.sharesData, facebookShareCount: action.value } };
    case "increment-share":
      return { ...state, sharesData: {...state.sharesData, shareCount: ++state.sharesData.shareCount } };
    case "increment-whatsapp-share":
      return { ...state, sharesData: {...state.sharesData, shareCount: ++state.sharesData.shareCount, whatsappShareCount: ++state.sharesData.whatsappShareCount } };
    case "increment-facebook-share":
      return { ...state, sharesData: {...state.sharesData, shareCount: ++state.sharesData.shareCount, facebookShareCount: ++state.sharesData.facebookShareCount } };
    case "supporting-campaigns-data":
      return { ...state, supportingCampaigns: action.value };
    case "campaign-updates-data":
      return { ...state, updates: {...state.updates, campaignUpdatesData: action.value} };
    case "project-updates-data":
      return { ...state, updates: {...state.updates, projectUpdatesData: action.value} };
    case "donor-info":
      return { ...state, donors: { ...state.donors, donationUserActivityInfo: action.value } };
    case "live-donor-count":
      return { ...state, donors: { ...state.donors, liveDonorCount: action.value } };
    case "reset":
      return initialState;
  }
};
